
import Vue from "vue"

export default Vue.extend({
  name: "ValidatedUploadCard",
  props: {
      name: String,
  },
  data: () => ({
    file: "",
    title: ""
  }),
})
