

import Vue from "vue"

export default Vue.extend({
    name: "PreventReloadMixin",
    methods: {
        clearPreventReload() {
            window.onbeforeunload = null
        },
        preventReload(event: Event) {
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
            // Recommended
            event.preventDefault()
            // Included for legacy support, e.g. Chrome/Edge < 119
            event.returnValue = true
        }
    },
    beforeMount() {
        window.onbeforeunload = this.preventReload
    },
})
