
import Vue from 'vue'
import MobilePhoneNumberInput from "@/components/MobilePhoneNumberInput.vue"
import CheckboxButton from "@/components/CheckboxButton.vue"

export interface Agency {
  id: number,
  name: string,
  logo: string | null,
  prefix: string | null
}

export default Vue.extend({
  name: 'SubscriptionContactDetails',
  components: {
    MobilePhoneNumberInput,
    CheckboxButton
  },
  props: {
    agencies: Array,
    bodyRequest: Object
  },
  data: () => ({
    agency_id: null as null | number,
    sex: "",
    first_name: "",
    last_name: "",
    email: "",
    postal_address: "",
    postal_complement: "",
    postal_code: "",
    postal_city: "",
    postal_country: "",
    transportation_means: [],
    favorite_jobs: [],
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  }),
  computed: {
    emailRule() {
      return (v: string) => (this.emailRegex.test(v) || !v) || this.$vuetify.lang.t('$vuetify.invalid_email')
    },
    requiredRule() {
      return (v: string) => !!v || this.$vuetify.lang.t('$vuetify.required_field')
    },
    mobile_phone_number() {
      return (this.$refs.phone as Vue & { getValue: () => boolean | string }).getValue()
    },
    nameLengthRule() {
      return (v: string) => ((v.length > 1 && v.length < 100) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    stringLengthRule() {
      return (v: string) => ((v.length > 1 && v.length < 254) || !v) || this.$vuetify.lang.t('$vuetify.invalid_length')
    },
    numericRule() {
      return (v: string) => (!isNaN(+v) || !v) || this.$vuetify.lang.t('$vuetify.is_numeric')
    },
    jobArrayRule() {
      return (this.favorite_jobs.length < 4) || this.$vuetify.lang.t('$vuetify.invalid_size_array')
    }
  },
  methods: {
    filterAgencies(item: Agency, queryText: string, itemText: string) {
      const words = [...queryText.toLocaleLowerCase()]
      const items = [...itemText.toLocaleLowerCase()]
      
      if(words.length < 2){
        return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      } else {
        return words.every(word => items.indexOf(word) > -1)
      }
    },
    previousStep() {
      this.$emit("step", 1)
      this.$emit("stepTitle", "$vuetify.preparation")
    },
    validateStep() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.getFormValues()
        this.$emit("step", 3)
        this.$emit("stepTitle","$vuetify.marital_status")
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    updateTransport(transport: string, checked: boolean) {
      if (checked) {
        (this.transportation_means as Array<string>).push(transport)
      } else {
        const index = (this.transportation_means as Array<string>).indexOf(transport)
        if (index > -1) {
          this.transportation_means.splice(index, 1)
        }
      }
    },
    getFormValues() {
      const inputs = ["sex", "agency_id", "first_name", "last_name", "email", "postal_address", "postal_complement", "postal_code", "postal_city", "postal_country", "transportation_means", "favorite_jobs"]
      inputs.forEach((document) => {
        delete this.bodyRequest[document]
        // If it's an array and not empty or if it is an integer (agency_id)
        if ((Array.isArray(this.$data[document]) && this.$data[document].length > 0) || Number.isInteger(this.$data[document])) {
          this.bodyRequest[document] = this.$data[document]
        } else if (!Array.isArray(this.$data[document]) && this.$data[document] !== "" && !Number.isInteger(this.$data[document])) {
          this.bodyRequest[document] = this.$data[document].trim()
        }
      })
      this.bodyRequest["mobile_phone_number"] = this.mobile_phone_number
      this.bodyRequest["url"] = `${this.$route.params.agencySubDomain}.dispojob.pro`
    }
  },
  watch: {
    agencies: function() {
      if (Object.keys(this.agencies).length === 1) {
        this.agency_id = (this.agencies[0] as Agency).id
      }
    },
    agency_id: function() {
      // To update logo and prefix from agency selection
      for (const agency of this.agencies) {
        // We only want the selected agency
        if ((agency as Agency).id === this.agency_id) {
          // To update logo
          if ((agency as Agency).logo !== null) {
            this.$emit("updateLogo", (agency as Agency).logo)
          } else {
            this.$emit("updateLogo", "dispojob_label.svg")
          }
          // To update prefix
          if ((agency as Agency).prefix !== null) {
            (this.$refs.phone as InstanceType<typeof MobilePhoneNumberInput>).setPrefix((agency as Agency).prefix as string)
          } else {
            (this.$refs.phone as InstanceType<typeof MobilePhoneNumberInput>).setPrefix("+33")
          }
          break
        }
      }
    }
  },
  mounted() {
    if (Object.keys(this.agencies).length === 1) {
      this.agency_id = (this.agencies[0] as Agency).id
    }
  }
})
