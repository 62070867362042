import { render, staticRenderFns } from "./SubscriptionContactDetails.vue?vue&type=template&id=0d0be7c0&scoped=true"
import script from "./SubscriptionContactDetails.vue?vue&type=script&lang=ts"
export * from "./SubscriptionContactDetails.vue?vue&type=script&lang=ts"
import style0 from "./SubscriptionContactDetails.vue?vue&type=style&index=0&id=0d0be7c0&prod&lang=sass&scoped=true"
import style1 from "./SubscriptionContactDetails.vue?vue&type=style&index=1&id=0d0be7c0&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0be7c0",
  null
  
)

export default component.exports