import { render, staticRenderFns } from "./SubscriptionDocuments.vue?vue&type=template&id=65adfc28&scoped=true"
import script from "./SubscriptionDocuments.vue?vue&type=script&lang=ts"
export * from "./SubscriptionDocuments.vue?vue&type=script&lang=ts"
import style0 from "./SubscriptionDocuments.vue?vue&type=style&index=0&id=65adfc28&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65adfc28",
  null
  
)

export default component.exports