
import Vue from 'vue'

export default Vue.extend({
  name: 'CheckboxButton',
  props: {
    name: String,
    logo: String
  },
  data: () => ({
    checked: false
  }),
  methods: {
    toggle(type: string) {
      this.checked = !this.checked
      this.$emit("update", type, this.checked)
      let elt = (this.$refs[type] as Vue).$el
      if (!this.checked) {
        elt.classList.remove("selected")
      } else {
        elt?.classList.add("selected")
      }
    }
  }
})
