
import Vue from "vue"
import AlertInfo from "@/components/AlertInfo.vue"
import SingleUploadCard from "@/components/SingleUploadCard.vue"
import MultipleUploadCard from "@/components/MultipleUploadCard.vue"
import ValidatedUploadCard from "@/components/ValidatedUploadCard.vue"
import axios from "axios"
import { EventBus } from "@/plugins/eventBus"

interface FormData {
  photo_document: null | string,
  cv_document: null | string,
  bank_identity_document: null | string,
  social_security_document: null | string,
  residence_proof_document: null | string,
  aptitude_medical_examination_document: null | string,
  last_work_certificate_document: null | string,
  identity_card_document: null | string,
  identity_card_verso_document: null | string,
  driver_licence_document: null | string,
  resident_permit_document: null | string,
  other_identity_document: null | string,
  passport_document: null | string,
  caces_documents: Array<string>,
  habilitation_documents:  Array<string>,
  license_documents: Array<string>,
  diploma_documents: Array<string>,
  other_documents: Array<string>,
  cet: boolean
}

  export default Vue.extend({
    name: 'SubscriptionDocuments',
    components: {
      AlertInfo,
      SingleUploadCard,
      MultipleUploadCard,
      ValidatedUploadCard
    },
    props: {
      bodyRequest: Object,
      kiosk: {
        default: false,
        type: Boolean
      }
    },
    data: function () {
      return{
        file: "",
        files: [],
        training_certificate: [
          {
            title: this.$vuetify.lang.t('$vuetify.caces'),
            ref: "caces_documents" 
          },
          {
            title: this.$vuetify.lang.t('$vuetify.habilitation'),
            ref: "habilitation_documents" 
          },
          {
            title: this.$vuetify.lang.t('$vuetify.license'),
            ref: "license_documents"
          },
          {
            title: this.$vuetify.lang.t('$vuetify.diploma'),
            ref: "diploma_documents" 
          },
          {
            title:  this.$vuetify.lang.t('$vuetify.other'),
            ref: "other_documents" 
          },
        ],
        identity_document: [
          {
            title: this.$vuetify.lang.t('$vuetify.identity_card'),
            ref: "identity_card_document" 
          },
          {
            title: this.$vuetify.lang.t('$vuetify.identity_card_verso'),
            ref: "identity_card_verso_document" 
          },
          {
            title: this.$vuetify.lang.t('$vuetify.passport'),
            ref: "passport_document" 
          },
          {
            title: this.$vuetify.lang.t('$vuetify.resident_permit'),
            ref: "resident_permit_document" 
          },
          {
            title: this.$vuetify.lang.t('$vuetify.subscription_page.other_id'),
            ref: "other_identity_document" 
          },
        ],
        formData: {
          photo_document: null,
          cv_document: null,
          bank_identity_document: null,
          social_security_document: null,
          residence_proof_document: null,
          aptitude_medical_examination_document: null,
          last_work_certificate_document: null,
          identity_card_document: null,
          identity_card_verso_document: null,
          driver_licence_document: null,
          resident_permit_document: null,
          other_identity_document: null,
          passport_document: null,
          caces_documents: [],
          habilitation_documents: [],
          license_documents: [],
          diploma_documents: [],
          other_documents: [],
          cet: false
        } as FormData,
        waitRequest: false
      }
    },
    methods: {
      previousStep() {
        this.$emit("step",3)
        this.$emit("stepTitle","$vuetify.marital_status")
      },
      validateStep() {
        if(this.formData.cv_document){
          this.getFormValues()
          this.createWorker()
        } else {
          EventBus.$emit('snackbar',{message: this.$vuetify.lang.t('$vuetify.subscription_page.mandatory_resume'), color: 'error'})
        }
      },
      getUploadFileName(ref: keyof FormData, data: string) {
        (this.formData[ref] as string) = data
      },
      pushUploadFileName(ref: keyof FormData, data: string) {
        (this.formData[ref] as string[]).push(data) 
      },
      displaySnackbar(message:string) {
        EventBus.$emit('snackbar',{message: message, color: 'error'})
      },
      getFormValues () {
        this.bodyRequest["cet"] = this.formData.cet
        const singleDocuments = ["cv_document", "photo_document", "social_security_document", "identity_card_document", "identity_card_verso_document", "passport_document", "resident_permit_document", "other_identity_document", "residence_proof_document", "last_work_certificate_document", "bank_identity_document", "aptitude_medical_examination_document", "driver_licence_document", "caces_documents", "habilitation_documents", "license_documents", "diploma_documents", "other_documents"]
        singleDocuments.forEach((document) => {
          let value = this.formData[(document as keyof FormData)]
          if ((Array.isArray(value) && value.length > 0) || value && !Array.isArray(value)) {
            this.bodyRequest[document] = value
          }
        })
      },
      createWorker() {
        this.waitRequest = true
        axios
          .post(`/v1/subscription/subscribe`, this.bodyRequest)
          .then(() => {
            this.$gtag.event('subscription', {
              'event_category': "completed",
              'event_label': this.bodyRequest["agency_id"]
            })
            this.$emit("step", 5)
            this.$emit("stepTitle", "$vuetify.confirmation")
          })
          .catch(error => {
            let textError = this.$vuetify.lang.t("$vuetify.subscription_page.error.unknown") // unknown error by default
            let step: null | number = null
            let title: null | string = null

            // collect errors message
            if (error.response) {
              if (error.response.status == 400) { // try to identify only error 400
                let errors = error.response.data[0].split(", ")
                let definedErrors: string[] = []
                errors.forEach((err: string) => {
                  if (this.$vuetify.lang.t("$vuetify.subscription_page.error." + err) !== "$vuetify.subscription_page.error." + err) { // if error localisation exists
                    definedErrors.push(this.$vuetify.lang.t("$vuetify.subscription_page.error." + err))
                  } // else case not handled

                  // Special case for redirection to previous step
                  if (step == null) { // only first qualified error get the redirection
                    switch (err) {
                      case "email or mobile_phone_number already taken":
                      case "Field space_id is required":
                      case "Invalid sex":
                      case "Invalid first_name":
                      case "Invalid last_name":
                      case "Invalid mobile_phone_number":
                      case "Invalid email":
                      case "Invalid postal_address":
                      case "Invalid postal_complement":
                      case "Invalid postal_code":
                      case "Invalid postal_city":
                      case "Invalid postal_country":
                      case "Invalid favorite_jobs size":
                      case "Invalid favorite_jobs type":
                        step = 2
                        title = "$vuetify.contact_details"
                        break
                      case "Invalid birth_date (awaited format 'YYYY-MM-DD')":
                      case "Invalid birth_date (wrong value)":
                      case "Invalid birth_city":
                      case "Invalid birth_country":
                      case "Invalid nir (Pattern)":
                      case "Invalid nir (Key)":
                        step = 3
                        title = "$vuetify.marital_status"
                        break
                    }
                  }
                })

                if (definedErrors.length > 0) {
                  textError = definedErrors.join(', ')
                }
              }
            } else if (error.request) { // client never received a response, or request never left (API container down)
              textError = this.$vuetify.lang.t('$vuetify.api_errors.network')
            }

            // display error part
            if (step == null) { // if no redirection to previous step detected
              EventBus.$emit('snackbar',{message: textError, color: 'error'})
            } else {
              this.$emit("displaySnackbarFromChild", textError)
              this.$emit("step", step)
              this.$emit("stepTitle", title)
            }
          })
          .finally(() => {
            this.waitRequest = false
          })
      }
    }
  })
