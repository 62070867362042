
import Vue from "vue"
import SubscriptionMaritalStatus from "@/components/subscription/SubscriptionMaritalStatus.vue"
import SubscriptionPreparation from "@/components/subscription/SubscriptionPreparation.vue"
import SubscriptionContactDetails from "@/components/subscription/SubscriptionContactDetails.vue"
import SubscriptionConfirmation from "@/components/subscription/SubscriptionConfirmation.vue"
import SubscriptionDocuments from "@/components/subscription/SubscriptionDocuments.vue"
import AlertError from "@/components/AlertError.vue"
import SubscriptionMixin from "@/mixins/SubscriptionMixin.vue"
import PreventReloadMixin from "@/mixins/PreventReloadMixin.vue"
import { VueConstructor } from "vue/types/umd"

export default (Vue as VueConstructor<Vue & InstanceType<typeof SubscriptionMixin> & InstanceType<typeof PreventReloadMixin>>).extend({
  name: "KioskSubscription",
  components: {
    SubscriptionPreparation,
    SubscriptionContactDetails,
    SubscriptionMaritalStatus,
    SubscriptionConfirmation,
    SubscriptionDocuments,
    AlertError
  },
  mixins: [
    SubscriptionMixin,
    PreventReloadMixin
  ],
  watch: {
    step: function(): void {
      if (this.step == 5) {
        this.clearPreventReload()
      }
    }
  }
})
