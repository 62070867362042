
import Vue from "vue"
import Subscription from "@/components/subscription/Subscription.vue"

export default Vue.extend({
  components: {
    Subscription,
  },
  beforeCreate() {
    this.$vuetify.theme.dark = false // we force light them on this view
  },
  data: () => ({
    show: true
  })
})
