
  import Vue from 'vue'
  import AlertWarning from '@/components/AlertWarning.vue'
  import AlertInfo from '@/components/AlertInfo.vue'
  import Locale from '@/components/Locale.vue'
  import { mutations } from '@/plugins/state'

  export default Vue.extend({
    name: 'SubscriptionPreparation',
    components: {
      AlertWarning,
      AlertInfo,
      Locale
    },
    data: () => ({
        consent: false,
        subscriptionStarted: false
    }),
    mounted() {
      this.$emit("stepTitle", "$vuetify.preparation")
      mutations.setLocale('fr')
    },
    methods: {
      nextStep() {
        this.$emit("step", 2)
        this.$emit("stepTitle", this.$vuetify.lang.t("$vuetify.contact_details"))
        if (this.subscriptionStarted === false) {
          this.$gtag.event('subscription', {
            'event_category': "started",
          })
          this.subscriptionStarted = true
        }
      }
    }
  })
