
import Vue from "vue"
import KioskSubscription from "@/components/subscription/KioskSubscription.vue"

export default Vue.extend({
  components: {
    KioskSubscription,
  },
  beforeCreate() {
    this.$vuetify.theme.dark = false // we force light them on this view
  },
  data: () => ({
    show: true
  })
})
